@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$row-padding: size("gutter--large") * 1.2;
$item-padding: size("gutter");

.block {
  overflow-y: scroll;
  max-height: calc(100vh - #{size("header-height")});
  background: var(--light);
  color: var(--primaryAlt);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  font-size: size("font--medium");
  background: var(--light);
  color: var(--primaryAlt);
  height: size("header-height");
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid rgba(255,255,255, 0.4);
}

button.button {
  border-radius: 0;
  width: size("ooc-row-height");
}

.dropdownButton {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.back {
  border: none !important;
  background: none;
  padding: 0;
  width: 56px !important;

  svg {
    transform: rotate(180deg);
  }

  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;

  & + & {
    border-top: 1px solid rgba(255,255,255,0.4);
  }

  &:last-child {
    border-bottom: 1px solid rgba(255,255,255,0.4);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: size("gutter");
  padding: 0;
  flex: 1 0 auto;
}

.items {
  @media (min-width: breakpoint("small")) {
    height: auto !important;
    width: 80% !important;

    &>div {
      display: flex;
      flex-wrap: wrap;
      margin: $row-padding - $item-padding;
    }
  }

  .item {
    font-weight: normal;
    font-size: size("font--medium-small");
    line-height: font("line-height--medium-small");
    color: var(--primaryAlt) !important;
  }
}

.item {
  flex: 1 0 auto;
  font-size: size("font--medium");

  display: block;
  background: var(--light);

  & {
    border-top: 1px solid rgba(255,255,255,0.4);
  }

  &__heading {
    padding: $item-padding;
  }
}

.name {
  padding: $item-padding;
}

.heading, .name {
  color: var(--primaryAlt);
  text-decoration: none !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: $row-padding $row-padding $row-padding 0;
  font-weight: bold;
  min-height: size("ooc-row-height");
  font-family: var(--fontHeader), var(--fontHeaderFallback, #{font("fallback")});

  align-items: center;
  padding: $item-padding;
  flex-grow: 1;
}

.title {
  justify-content: center;
}

.chevron {
  transition: transform transition("duration--atoms") transition("ease-in");
  transform: rotate(90deg);
  padding: $item-padding;
}

.open .chevron {
  transform: rotate(90deg) scaleX(-1);
}

.close {
  display: flex;
  align-items: center;

  @include resetInput;
  cursor: pointer;
}

.parent {
  @media (max-width: (breakpoint("small") - 1px)) {
    .heading {
      font-size: size("font--medium-small");
      line-height: font("line-height--medium-small");
    }
  }
  &__item {
    display: flex;
    flex: 1 0 auto;
  }
}
