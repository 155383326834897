@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  cursor: pointer;
  user-select: none;
  position: relative;
}

.dropdown {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  position: absolute;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% - 24px);
  width: 240px;
  max-height: 400px;
  overflow: auto;
  cursor: initial;
  z-index: 901;

  a {
    padding: size("gutter--small");
    color: color("primary");
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  > * + * {
    border-top: 1px solid color("border--muted");
  }
}
