@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$paddingTopLarge: 250px;
$paddingTopSmall: 150px;
$maxWidthLoginForm: 425px;

:export {
  small: strip-unit(breakpoint(small));
}

.header {
  &Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: size("header-height");
  }

  a {
    color: var(--light);
    font-weight: bold;
    font-size: size("font--medium-small");
  }
}

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1 0 auto;
  color: var(--invertedText);

  h1 {
    font-size: size("font--title");
  }
}

.wrapper {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
}

.termsHeader {
  @media (min-width: breakpoint("large")) {
    padding-bottom: size("gutter--huge");
  }
}
