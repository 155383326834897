@import "ui-components/_variables.scss";

$header-height: 180px;

:export {
  tiny: strip-unit(breakpoint("tiny"));
}


.hero {
  width: 100%;
  margin: auto;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: top center;
    width: 100%;
  }

  .image {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .content {
    display: block;
    color: var(--invertedText);
    width: 100%;
    margin-top: $header-height;
    padding: 0 0 size("gutter--huge");

    & h1 {
      font-size: size("h1");
      margin-bottom: 1rem;
    }
  }
  .heroIcon {
    max-width: 200px;
  }
}
