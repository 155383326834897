@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  display: flex;
  align-items: center;
  color: var(--invertedText);

  img {
    max-width: 42px;
  }

  .slabBackground {
    background: var(--light);
    padding: size("gutter--mini");
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .slabLarge {
    img {
      max-width: 60px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    font-size: size("font--small");
    margin-right: size("gutter--mini");
  }

  .separator {
    margin: 0 math.div(size("gutter--small"), 2);
  }

  @media (max-width: breakpoint("medium")) {
    .content, .slabLarge, .slabBackground {
      display: none;
    }
  }
  @media (max-width: breakpoint("small")) {
    display: none;
  }
}
