@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$pagination-btn-size: 2.5rem;

:export {
  mqFour: strip-unit(breakpoint("medium"));
  mqThree: strip-unit(breakpoint("small"));
  mqTwo: strip-unit(breakpoint("tiny"));
}

.titlePrefix {
  font-weight: normal;
}

.filterbar {
  margin-bottom: size("gutter--small");
}

.header {
  font-size: size("font--title");
  margin-bottom: size("gutter--small");
  display: flex;

  h1 {
    margin-bottom: 0;
    margin-right: size("gutter--mini");
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-direction: column;
  }
}

.activeFilters {
  @media (max-width: (breakpoint("tiny") - 1px)) {
    width: 100%;
  }
}

.total_count {
  margin: 0;
  margin-top: size("grid-gutter");
  margin-bottom: size("gutter--large");
  color: var(--textMuted);
  font-size: size("font");
  line-height: 1.25;

  @media (min-width: (breakpoint("small") - 1px)) {
    align-self: flex-end;
  }
}

.recommendedProducts {
  margin-bottom: 0;
  & > div {
    padding-bottom: 0;
  }
}

.paginationWrapper {
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: size("gutter--large") 0;

  span {
    padding: 0 0.5rem;
  }

  &Button {
    display: flex;
    justify-content: center;
    align-content: center;
    background: var(--primary);
    color: var(--light);
    line-height: $pagination-btn-size;

    height: $pagination-btn-size;
    width: $pagination-btn-size;

    &.active {
      background: var(--primary-d2);
      text-decoration: underline;
    }

    &:first-child {
      border-top-left-radius: $pagination-btn-size;
      border-bottom-left-radius: $pagination-btn-size;
    }

    &:last-child {
      border-top-right-radius: $pagination-btn-size;
      border-bottom-right-radius: $pagination-btn-size;
    }
  }
}

.activeFiltersRow {
  display: flex;
  align-items: center;
  
  &Header {
    margin-bottom: 0;
    margin-right: size("gutter");

    @media (max-width: (breakpoint("small") - 1px)) {
      margin-bottom: size("gutter--small");
    }
  }

  &Count {
    color: var(--primary);
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    margin-bottom: size("gutter--small");
  }
}
