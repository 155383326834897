@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block,
.contentBlock {
  color: color("text");
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: z-index("overlay") + 1;

  @media (max-width: breakpoints("medium")) {
    height: 120px;
  }
}

.contentBlock {
  position: relative;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: #0007;
}

.content {
  font-size: size("font--small");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: size("border-radius");
  position: absolute;
  background: #fff;
  width: 70%;
  max-height: 80vh;
  max-width: breakpoint("small");

  @media (max-width: breakpoint("small")) {
    width: 90%;
  }

  .icon {
    color: color("notice--text");
    min-width: 24px;
  }

  .textContent {
    box-sizing: border-box;
    flex: 1;
    padding: size("gutter--small") size("gutter--large") 0;
    max-width: 100%;
    overflow-y: scroll;

    .heading {
      margin-top: size("gutter");
    }

    a {
      border-bottom: 1px dotted color("notice--text");
      color: color("notice--text");

      &:hover,
      &:focus {
        border-bottom: none;
        text-decoration: underline;
        background-color: transparent;
      }

      &:focus {
        outline: 1px color("notice--text");
        box-shadow: none;
      }
    }
  }

  .termLink {
    text-align: left;
    margin-bottom: size("gutter");
    width: 100%;
  }

  .actions {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .confirm {
    display: flex;
    padding: size("gutter--large") size("gutter");
    justify-content: center;
    gap: size("gutter");
    width: 100%;
    border-top: 1px solid color("border--muted");

    .accept,
    .close {
      text-transform: none;
      flex-basis: 50%;
    }

    button {
      @media (max-width: breakpoint("tiny")) {
        font-size: #{size("font--mini")} + #{0.1rem} !important;
      }
    }
  }

  .options {
    padding: size("gutter") size("gutter");
    display: flex;
    background-color: color("background");
    border-radius: size("border-radius");
    flex-direction: row;
    justify-content: center;

    .option {
      padding: size("gutter");
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: breakpoint("tiny")) {
        padding: size("grid-gutter");
      }

      .toggleWrapper {
        position: relative;
      }
    }

    .disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }

    .label {
      margin-bottom: size("grid-gutter");
    }
  }

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--small");
    flex-direction: column;
    align-items: flex-start;

    .text {
      margin: 0;
      margin-bottom: size("gutter");
    }
  }
}
