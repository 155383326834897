@import "ui-components/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: fixed;
  height: size("header-height") !important;
  z-index: z-index("system");
  top: 0;
  left: 0;
  right: 0;
  transition: all transition("duration--organisms") transition("ease-ease");


  @media (max-width: breakpoint("small")) {
    &.hasMobileSubNav {
      height: size("header-height") + size("sub-header-height") !important;
    }
  }

  &.fullMenu {
    margin-bottom: size("gutter--large") * 2;
  }

  @media (min-width: breakpoint("large")) {
    &.fullMenu {
      height: size("header-height") + size("sub-header-height") !important;
    }
    &.empty {
      height: size("header-height") !important;
    }
  }

  &.active {
    box-shadow: -1px 3px 15px 0 rgba(141, 151, 166, .16);
  }
  &.onHomeView {
    position: fixed;
    background-color: transparent;
  }
  &.active.onHomeView {
    background-color: var(--primaryAlt);
  }
}
