@use "sass:math";
@import "ui-components/_variables.scss";

.wrapper {
    margin-bottom: size("gutter--huge")
}

.article {
    border-left: 3px var(--primary) solid;
    padding: 0 size("gutter--large");
}