@import "ui-components/_variables.scss";

.button {
    width: 100%;

    &:hover {
        text-decoration: none !important;
        background-color: var(--border) !important;
    }

    @media (max-width: (breakpoint("small") - 1px)) {
        display: flex !important;
        margin-left: auto;
    }
}

.primaryButton, .button {
    font-weight: 500 !important;
  }

.primaryButton {
    border: none !important;

    &:hover {
        text-decoration: none !important;
    }

    .text {
        padding-right: size("grid-gutter");
    }

    .chevron {
        transition: transform 0.2s ease-in-out;
        transform: rotate(90deg);
        height: 24px !important;
        width: 24px !important;
    }
}

.buttonOnOpen {
    border-radius: 4px 4px 0 0 !important;
}

.chevronOnOpen {
    transform: rotate(270deg) !important;
}

.foldable {
    display: flex;
    flex-direction: column;

    a:last-of-type > button {
        border-radius: 0 0 4px 4px !important;
    }
}
