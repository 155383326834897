@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  animationDuration: strip-unit(transition("duration--organisms"));
}

.items {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-size: size("font--medium");

  color: var(--primaryAlt);
  font-weight: bold;
  cursor: pointer;

  user-select: none;

  &:hover {
    text-decoration: none;
  }

  svg + span {
    margin-left: size("gutter--mini");
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--fontHeader), var(--fontHeaderFallback, #{font("fallback")});
  font-size: 1.25rem;
  margin-left: 0;
  width: 100%;
  padding: 0 size("gutter");
  border-bottom: 1px solid rgba(255,255,255, 0.4);
  min-height: size("ooc-row-height");
  color: var(--primaryAlt);
  font-weight: 700;
  cursor: pointer;
  user-select: none;

  &, svg {
    color: var(--primaryAlt);
  }
}

.categories, .brands, .search {
  color: var(--primaryAlt);
  background-color: var(--light);
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  min-height: 100vh;
  transition: transform transition("duration--organisms") transition("ease-out");
  transform: translateX(100%);
  opacity: 0;

  &.active {
    transform: translateX(0%);
    opacity: 1
  }

  &.isClosing {
    transform: translateX(100%) !important;
    opacity: 1;
  }
}

.mode__categories .categories {
  display: block;
}

.mode__brands .brands {
  display: block;
}

.mode__search .search {
  display: flex;
}

.search {
  background: var(--light);
  flex-direction: column;
  align-items: stretch;
}
