@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummy {
  .row {
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
    height: size("font--large");
    margin-bottom: size("gutter--mini");
    border-radius: size("border-radius");
  }

}

.contentWrapper {
  padding: size("gutter");
  background-color: var(--light);
  margin: size("grid-gutter") 0 size("gutter--huge");
  box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);
}
