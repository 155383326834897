@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$paddingTopLarge: 250px;
$paddingTopSmall: 150px;
$maxWidthLoginForm: 425px;

.block {
  width: 100%;
  display: grid;
  @media (min-width: breakpoint("small")) {
    grid-template-rows: 17rem 1fr;
  }
}

.title {
  font-size: size("font--huge");
}

.content {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.bottom {
  color: var(--text);
  height: 100%;
  width: 100%;
  padding: size("gutter--large");
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .inner {
    max-width: 45rem;
  }

  .inner, .footerInner {
    margin: 0 auto;
    margin-top: size("gutter--huge");

    h1 {
      color: var(--primary);
    }
  }
}

.top {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  min-width: $maxWidthLoginForm;
  padding: size("gutter--section") size("gutter--larger");

  @media (max-width: (breakpoint("small") - 1px)) {
    min-width: 0;
    width: 100%;
    padding:size("gutter--larger");
  }
}

.footer {
  width: 100%;
  color: var(--text);
  padding: size("gutter--large");
  margin-top: size("gutter--large");

  a {
    color: var(--text);
  }

  .footerInner {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    .cookieButton {
      &:hover {
        text-decoration: underline;
      }

      @media (max-width: breakpoint("mini")) {
        margin-bottom: size("gutter--small");
      }
    }

    .spacing {
      @media (min-width: breakpoint("mini")) {
        margin-right: size("gutter--small");
      }
    }
  }
}
