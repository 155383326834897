@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 100%;
  }
}

.block {
  flex: 1;
  display: block;
  border: 1px solid var(--border);
  border-radius: var(--borderRadius);
  padding: size("gutter--large");
  display: flex;
  align-items: center;
  width:100%;

  @media (max-width: (breakpoint("medium") - 1px)) {
    padding: size("gutter");
  }

  &:hover {
    text-decoration: none;
  }
}

.top {
  text-align: right;
}

.image {
  height: 70px;
  width: 70px!important;
  border-radius: 100%;
  flex-shrink: 0;
  margin-right: size("gutter");
}

.title {
  font-family: var(--fontBody), var(--fontBodyFallback, #{font("fallback")});
  color: var(--text);
  margin-top: size("gutter");
  margin-bottom: size("gutter");
  font-size: size("font--large");

  .block:hover & {
    color: var(--primary);
    text-decoration: underline;
  }
}

.content {

  // limit content
  $description-rows: 2;
  font-size: size("font--small");
  line-height: font("line-height--medium-small");
  -webkit-line-clamp: 2;
  max-height: #{#{$description-rows * font("line-height")}em};
  overflow: hidden;
  text-overflow: ellipsis;


  &::after {
    content: "";
    display: block;
    width: 8ch;
    height: 1em * font("line-height");
    background-image: linear-gradient(to left, rgba(var(--light), 1), rgba(var(--light), 0));
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.date,
.content {
  color: var(--textMuted) !important;
}

.shadow {
  opacity: 0;
  transition: opacity transition("duration--atoms") transition("ease-in");
  box-shadow: 0 12px 32px 0 rgba(0, 20, 31, 0.12);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .block:hover & {
    opacity: 1;
  }
}

.dummy {
  &_block {
    flex: 1;
    width: 100%;
    border: 1px solid var(--border);
    border-radius: var(--borderRadius);
    padding: size("gutter--large");

    @media (max-width: (breakpoint("medium") - 1px)) {
      padding: size("gutter");
    }
  }

  &_top {
    display: flex;
    justify-content: flex-end;
  }

  &_date {
    height: size("font");
    width: 50px;
    max-width: 50px;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 100px;
    animation: shine 1.5s infinite linear;
  }

  &_title {
    margin-top: size("gutter");
    margin-bottom: size("gutter");
    height: size("font--large");
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  &_content {
    height: size("font") * 4;
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }
}
