@import "ui-components/_variables.scss";

.block {
  min-width: 1px;
  min-height: 1px;

  svg {
    overflow: visible;
  }
  img {
    max-height: 86px;
    @media (max-width: (breakpoint("small") - 1px)) {
      max-height: 70px;
    }
  }
}

.name {
  font-family: font("header");
  font-size: size("font--medium");
}

.svg {
  color: var(--primaryAlt);
}
