@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  color: var(--text);
  padding: size("gutter--larger");
  width: 100%;
}

.wrapper {
  max-width: 45rem;
  margin: auto;
}

.noScriptParagraph {
  text-align: center;
  font-size: size("font--medium-small") !important;
  padding-top: size("gutter");
  color: color("negative");
}

.form form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;

  button {
    white-space: nowrap;
  }

  > * + * {
    margin-left: size("gutter");

    @media (max-width: breakpoint("tiny")) {
      margin-left: 0;
    }
  }

  @media (max-width: breakpoint("tiny")) {
    flex-direction: column;
    align-items: initial;
    margin: 0;
  }
}

.bottom {
  margin-top: size("gutter");

  @media (max-width: (breakpoint("mini") - 1px)) {
    flex-direction: column;
  }
}

.no_account {
  @media (min-width: (breakpoint("mini") + 1px)) {
    text-align: right;
  }
}

.forgot_password, .no_account {
  display: flex;
  align-items: center;

  p, a {
    margin: 0;
  }

  a, span {
    color: var(--primary);
    margin-left: math.div(size("gutter--small"), 2);
  }
}
