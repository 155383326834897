@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$bottom-block-width: 775px;

* {
    scroll-behavior: smooth;
}

:target {
    scroll-margin-top: calc((#{(size("header-height") + size("sub-header-height") + size("header-margin"))}));

    @media (max-width: breakpoint("large")) {
        scroll-margin-top: calc((#{(size("header-height") + size("header-margin"))}));
    }
}

.block {
   background: #f3f3fa;

    h1, h2, h3 {
        margin: size("gutter--mini") 0 !important;
    }
}

.fullWidth {

    &__Top, &__Bottom {
        width: 100vw;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: size("gutter--huge") size("gutter--larger");
        margin-bottom: size("gutter--large");

        p {
            max-width: 60ch;
        }

        h1 {
            margin: size("gutter--small");
        }
    }

    &__Top {
        background-image: url("/assets/rexel/rexel-cloud.jpeg");
        background-position: center center;

        @media (max-width: 1239px) {
            margin-top: calc(-1 * #{size("gutter--larger")});
        }

        .introField {
            text-align: center;
            padding: size("gutter--medium");
        }
    }

    &__Bottom {
        box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
        
        .imgRight {
            max-width: 430px;
    
            @media (max-width: breakpoint("small")) {
                width: 100%
            }
        }
    }
}

.cardsField {
    margin-bottom: size("gutter--huge");

    h1 {
        text-align: center;
    }

    .cards {
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: size("gutter--large");
        margin-top: size("gutter--large");
        margin-bottom: size("gutter--large");

      @media (max-width: breakpoint("small")) {
            flex-direction: column;
        }

    }

    .card {
        flex: 0 1 33.3%;
        padding-bottom: size("gutter");
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
        border-radius: 4px;
        text-align: center;

        h3 {
            margin-bottom: 0 !important;
        }

        p {
            margin: 0 !important;
        }

        .icon {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: size("gutter");
            margin-bottom: size("gutter--mini") !important;
            border-bottom: solid 1px #eee;

            .container {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #7789be;
                border-radius: 50%;
                height: 75px;
                width: 75px;
                padding: size("gutter--mini");
            }

            svg {
                max-height: 100%;
                max-width: 100%;
                width: 45px;
                height: 45px;
                fill: #fff;
            }
        }

        .cardBtn {
            display: table;
            background-color: var(--primary);
            color: var(--light);
            padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
            margin-top: size("gutter");
            border-radius: var(--borderRadius);
        }
    }
}

.pointsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: size("gutter--larger");

    @media (max-width: breakpoint("medium")) {
        flex-direction: column;
    }

    .pointsField {
        img {
            margin-top: size("gutter--larger");
            display: block;
            width: 60ch;
            max-width: 100%;
        }

        .pointsBtn {
            font-size: size("font--small");
        }
    }
}

.bottomBlocksWrapper {

    .bottomBlocks {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: size("gutter");
        margin-bottom: size("gutter--huge");

        @media (max-width: breakpoint("medium")) {
            margin-top: size("gutter--larger");
            margin-bottom: size("gutter--larger");
        }

        .bottomBlock {
            background: #fff;
            max-width: $bottom-block-width;
            padding: size("gutter");
            box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
            border-radius: 4px;

            span {
                margin-left: size("gutter");
            }
        }

        ul {
            list-style: disc;
            margin: size("grid-gutter") 0;
            margin-left: size("gutter");

            li {
                line-height: 1.4;
            }
        }
    }
}