@import "ui-components/_variables.scss";

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.height {
  height: size("header-height");

  @media (min-width: breakpoint("large")) {
    .fullMenu:not(.onEarn) & {
      height: size("header-height") + size("sub-header-height");
    }
  }
}

@media (max-width: breakpoint("small")) {
  .hasMobileSubNav .height {
    height: size("header-height") + size("sub-header-height") !important;
  }
}


.onHomeView {
  .height {
    display: none;
  }

  .children {
    padding-top: 0;
    padding-bottom: 0;

  }
  .children > div {
    padding-bottom: 0;
  }
}

.notices {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: z-index("popup") + 1;
  box-shadow: 0 -6px 36px 0 rgba(0, 0, 0, 0.12);

  > div:not(:first-child) {
    border-top: 1px solid color("border--muted");
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.children {
  display: flex;
  flex-direction: column;
  padding-top: size("gutter--larger");
}

.children > div {
  padding-bottom: size("gutter--larger");
}

.bannerWarning {
  background-color: #fef7f8;
  text-align: center;

  @media (min-width: 1300px) and (max-width: 1400px) {
    margin-top: size("gutter--mini");
  }
  @media (min-width: 1240px) and (max-width: 1300px) {
    margin-top: size("gutter--medium");
  }

  @media (max-width: 1240px) {
    margin-top: size("gutter--larger") * -1;
    margin-bottom: size("gutter--larger");
  }

  .title[class] {
    margin: 0;
  }
}
