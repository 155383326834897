@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@import "../../shared/src/ui-components/CartMini.scss";

.item {
  &Name {
    color: var(--text) !important;
  }

  &Brand {
    color: color("text--muted");
    font-size: size("font--small");
    margin-top: 0;
    margin-bottom: 0;
  }

  &Qty {
    margin-top: 0;
    color: var(--text);
    font-size: size("font--small");
  }
}
