@use "sass:math";

@import "ui-components/_variables.scss";

$image-wrapper-max-height: 240px;

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  background-color: var(--background);
}

.promotion {
  display: flex;
  background-color: var(--light);
  color: var(--text);
  box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);

  .left,
  .right {
    width: 50%;
  }

  .left {
    margin-bottom: 0;
    margin-right: size("gutter--large");
  }

  .right {
    margin-left: size("gutter--large");
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: size("gutter--large");
  }

  .description {
    margin-bottom: 0;
  }

  .ctaLarge {
    margin-top: size("gutter--large");
    font-size: size("font") !important;
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex-direction: column;

    .left,
    .right {
      width: 100%;
      margin: initial;
    }

    .left {
      &:not(.empty) {
        margin-bottom: size("gutter");
      }
    }
  }
}

.carousel {
  width: 100%;
  display: block;

  @media (max-width: (breakpoint("medium") + (size("input-icon-size") * 3))) {
    button {
      margin-right: calc(#{size("grid-gutter")} - 0.15rem);
    }
  }

  @media (max-width: breakpoint("tiny")) {
    button:not(:first-child):not(:last-child) {
      display: none;
    }
  }
}

.popularCategories,
.popularProducts {
  padding: 0;

  h2 {
    color: var(--primary);
  }

  a {
    h2 {
      color: var(--primary) !important;
    }
  }
}

.howToEarnImage {
  height: 100%;
  object-fit: cover;
}

.currentPageInfoTop {
  display: flex;
  flex: 1 1 auto;
  padding: 0 size("gutter--large") 0;

  &>div:first-child {
    width: math.div(2, 3) * 100%;
  }

  &>div:last-child {
    width: math.div(1, 3) * 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: (breakpoint("small") - 1px)) {
      margin-top: size("gutter--huge");
    }
  }

  &>div {
    min-width: 280px;
    flex-grow: 1;
  }

  >div:first-child:last-child {
    width: 100%;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-wrap: wrap;
    padding: 0;
  }

  .currentInfoCardWrapper {
    min-height: 540px;
  }
}

.currenInfoCardsSectionCardWrapper {
  display: flex;
  flex-wrap: wrap;
  min-width: 280px;
  flex: 1 1 auto;

  @media (max-width: (breakpoint("medium") - 1px)) {
    margin: size("gutter--small") 0 0;
    flex: 0 0 100%;
  }

  &Alt {
    min-width: 100%;
    margin: 0;
  }
}

.currenInfoCardsSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: size("gutter");

  @media (max-width: (breakpoint("small") - 1px)) {
    grid-template-columns: 1fr;
  }

  .currentInfoCard {
    border-radius: var(--borderRadius);

    img {
      margin-bottom: size("grid-gutter");
      width: 100%;
    }

    .currentInfoCardContent {
      padding: size("gutter");
      height: 100%;
    }

    .currentInfoCardDate {
      margin-bottom: size("grid-gutter");
      font-size: size("font--small");
      color: color("text--muted");
    }

    h3 {
      margin-bottom: size("grid-gutter");
    }

    p {
      margin-top: 0;
    }
  }

  .currenInfoCardImage {
    display: flex;
    justify-content: center;
    margin: size("grid-gutter") 0;
  }

  &Alt {
    grid-template-columns: 1fr 1fr;

    @media (min-width: breakpoint("medium")) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}


.currentPageInfoBottom {
  @media (max-width: (breakpoint("medium") - 1px)) {
    .currentInfoCardWrapper {
      max-width: 100%;
    }
  }
}

.currentInfoDescription {
  font-size: size("font--small");
  max-width: 600px;

  a {
    display: table;
    background-color: var(--primary);
    color: var(--light);
    padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
    margin-top: size("gutter") * 2;
  }
}

.newsListWrapper {
  padding: size("gutter");
  box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);
  margin: size("grid-gutter");
  background-color: var(--light);
  color: var(--text);

  &>h2 {
    font-size: size("h2");
    margin-bottom: 0;
    color: var(--primary);
  }

  a {
    padding: 0;
    margin-top: size("gutter--medium");
    border: none;
    word-break: break-word;

    h2 {
      box-sizing: border-box;
      overflow-y: hidden !important;
      font-size: size("h3");
      margin: size("grid-gutter") 0 0;
      max-height: calc((#{size("h3")} * 1.4) * 2);
    }

    &>div:first-child {
      display: none;
    }

    * {
      text-align: left;
    }

    header span {
      font-size: size("font--small");
    }

    p {
      font-size: size("font");
    }
  }

  @media (min-width: breakpoint("small")) and (max-width: (breakpoint("medium") - 1px)) {
    padding: size("gutter--mini") size("gutter--mini") size("gutter");
  }
}

.newsListLink:not(#foo) {
  background-color: var(--primary);
  color: var(--light);
  display: inline-block;
  padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
  font-size: size("font--small");
  line-height: font("line-height--paragraph");
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-top: auto;
  align-self: flex-start;

  @media (max-width: (breakpoint("medium") + (size("input-icon-size") * 2))) {
    margin-left: size("grid-gutter");
  }

  @media (max-width: breakpoint("small")) {
    align-self: flex-end;
  }
}

.newsListItems {
  margin-bottom: size("gutter--medium");

  @media (min-width: breakpoint("small")) {
    max-width: 336px;
  }
}

.currentInfoSectionTop {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -#{size("grid-gutter")};
  margin-right: -#{size("grid-gutter")};
  margin-bottom: size("gutter--large");

  @media (max-width: breakpoint("medium")) {
    margin-bottom: 0;
  }

  .currentInfoCardsWrapper {
    &__left {
      flex: 0 0 70%;

      @media (max-width: breakpoint("medium")) {
        flex: 0 0 100%;
      }
    }

    &__right {
      flex: 0 0 30%;

      @media (max-width: breakpoint("medium")) {
        flex: 0 0 100%;
      }
    }
  }
}

.currentInfoCardWrapper {
  padding: size("grid-gutter");
  display: flex;
  flex-wrap: wrap;
  min-width: 280px;
  flex: 0 0 50%;
  height: 100%;

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 0 0 100%;
  }
}

.currentInfoCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--light);
  color: var(--text);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  flex-grow: 1;

  transition: transition("duration--atoms") transition("ease-in");

  >div:first-child:not(:last-child) {
    width: 100%;
  }

  &ImageWrapper {
    img {
      width: 100%;
      max-height: 400px;

      @media (min-width: (breakpoint("medium") - 1px)) {
        max-height: $image-wrapper-max-height;
      }
    }
  }

  &Content {
    padding: size("gutter");
  }

  &Alt {
    padding: size("gutter--large");

    @media (max-width: (breakpoint("small") - 1px)) {
      padding: size("gutter");
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title,
  .description {
    max-width: 600px;
  }
}

.cta {
  padding: size("gutter--small") size("gutter--medium");

  &Large {
    font-size: size("font--large");
  }
}

.top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: size("gutter");

  @media (max-width: breakpoint("tiny")) {
    flex-direction: column;
    margin-bottom: size("gutter--large");
  }
}

.featuredProducts,
.earnOnline {
  display: flex;
  justify-content: center;
}

.title {
  font-size: size("h1");
  margin-bottom: size("gutter--small");
  color: var(--primary);
}

.popularCategoryCarousel>h2 {
  margin-bottom: size("gutter--small");
}

.description {
  font-size: size("font--small");
}

.ingress {
  margin-bottom: 0;
  font-size: size("font--small");
}

@media (max-width: (breakpoint("tiny") - 1px)) {

  .cta,
  .ctaLarge {
    padding-left: 0;
    font-size: size("font") !important;
  }
}

.wrapper {
  margin-top: size("gutter--huge");

  @media (max-width: (breakpoint("tiny") - 1px)) {
    margin-top: size("gutter--huge") 0;
  }

  &>*:not(:first-child) {
    margin-top: size("gutter--huge");
  }
}

.suppliersBlock {
  padding: 0 size("gutter--huge") size("gutter--huge");
  background: color("body--secondary");
  margin-top: size("gutter--huge");

  @media (max-width: (breakpoint("large") - 1px)) {
    padding: size("gutter--huge") size("gutter--medium-large");
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    padding: size("gutter--huge") size("gutter--small");
  }


}

.suppliersWrapper {
  .title {
    margin-bottom: size("gutter--medium-large") !important;
  }

  .contentWrapper {
    padding: size("gutter");
    background-color: var(--light);
  }
}

.uploadImage {
  .btn {
    padding: size("gutter");
    text-transform: none;
  }

  .cta {
    padding-right: size("gutter--medium");
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: size("gutter--medium");
  }
}

.uploadedImageContainer {
  @media (max-width: (breakpoint("mini") + 20px)) {
    flex: 0 0 100%;
  }

  .innerImageContainer {
    height: 290px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .imageContainerTitle {
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.imageViewDesc {
  margin-bottom: size("gutter--larger");
}

.currentinfowrapper {
  padding: 0;
}